const state = {
    prospectId: "",
    orgId: "",
    userData: {},
    portalAssets: {},
    isCoPilot: false,
};

const getters = {
    getProspectId: state => state.prospectId,
    getOrgId: state => state.orgId,
    getUser: state => state.userData,
    getAssets: state => state.portalAssets,
    getIsCoPilot: state => state.isCoPilot,
};

const actions = {
    fetchProspectId({ commit }, data) {
        commit('setProspectId', data)
    },

    fetchOrgId({ commit }, data) {
        commit('setOrgId', data)
    },

    fetchUser({ commit }, data) {
        commit('setUser', data);
    },

    fetchAssets({ commit }, data) {
        commit('setAssets', data);
    },

    fetchIsCoPilot({ commit }, data) {
        commit('setIsCoPilot', data);
    },
};

const mutations = {
    setProspectId: (state, id) => state.prospectId = id,
    setOrgId: (state, id) => state.orgId = id,
    setUser: (state, user) => state.userData = user,
    setAssets: (state, assets) => state.portalAssets = assets,
    setIsCoPilot: (state, status) => state.isCoPilot = status,
};

export default {
    state,
    getters,
    actions,
    mutations,
};
