<template>
  <router-view></router-view>
</template>

<script>
import "./filters.js";

export default {
  name: "App",
};
</script>
