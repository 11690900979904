import Vue from "vue";
import moment from "moment";
moment.locale('en')

Vue.filter("formatDate", (date) => {
    return moment(new Date(date)).format("MMM D, YYYY");
})

Vue.filter("formatNumber", (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
});

Vue.filter("capitalize", (word) => {
    return word.replace(/\b\w/g, l => l.toUpperCase())
});