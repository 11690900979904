<template>
  <div>
    <svg
      width="198"
      height="140"
      viewBox="0 0 198 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M158 0H38C32.4772 0 28 4.47715 28 10V130C28 135.523 32.4772 140 38 140H158C163.523 140 168 135.523 168 130V10C168 4.47715 163.523 0 158 0Z"
        fill="#EAECF0"
      />
      <g filter="url(#filter0_d_1615_5072)">
        <path
          d="M142 10H7C4.23858 10 2 12.2386 2 15V40C2 42.7614 4.23858 45 7 45H142C144.761 45 147 42.7614 147 40V15C147 12.2386 144.761 10 142 10Z"
          fill="white"
        />
      </g>
      <path
        d="M71 18H45C43.3431 18 42 19.3431 42 21C42 22.6569 43.3431 24 45 24H71C72.6569 24 74 22.6569 74 21C74 19.3431 72.6569 18 71 18Z"
        fill="#D0D5DD"
      />
      <path
        d="M89 31H45C43.3431 31 42 32.3431 42 34C42 35.6569 43.3431 37 45 37H89C90.6569 37 92 35.6569 92 34C92 32.3431 90.6569 31 89 31Z"
        fill="#EAECF0"
      />
      <path
        d="M29 13H10C7.23858 13 5 15.2386 5 18V37C5 39.7614 7.23858 42 10 42H29C31.7614 42 34 39.7614 34 37V18C34 15.2386 31.7614 13 29 13Z"
        fill="#98A2B3"
      />
      <path
        d="M17.2222 30.78H19.2222L19.2442 30.01C19.2442 28.998 19.8822 28.734 20.7842 28.448C21.689 28.1906 22.4854 27.6456 23.053 26.8955C23.6206 26.1453 23.9285 25.2307 23.9302 24.29C23.95 23.7157 23.8504 23.1435 23.6376 22.6096C23.4248 22.0758 23.1036 21.5919 22.6941 21.1886C22.2846 20.7854 21.7959 20.4715 21.2589 20.2669C20.7219 20.0623 20.1482 19.9714 19.5742 20C18.9676 19.9868 18.3647 20.0969 17.8019 20.3237C17.2392 20.5505 16.7284 20.8893 16.3005 21.3194C15.8726 21.7495 15.5365 22.2621 15.3126 22.8261C15.0888 23.39 14.9818 23.9935 14.9982 24.6H17.0662C17.0584 24.2651 17.1174 23.9321 17.24 23.6203C17.3625 23.3085 17.546 23.0244 17.7798 22.7845C18.0136 22.5445 18.2929 22.3537 18.6013 22.2231C18.9098 22.0925 19.2412 22.0248 19.5762 22.024C19.8783 22.0065 20.1806 22.0542 20.4626 22.1639C20.7446 22.2737 20.9997 22.4428 21.2105 22.6599C21.4213 22.8769 21.5829 23.1368 21.6843 23.4219C21.7857 23.707 21.8246 24.0106 21.7982 24.312C21.7849 24.7999 21.6152 25.2706 21.3142 25.6548C21.0132 26.039 20.5968 26.3164 20.1262 26.446L19.4002 26.734C18.6894 26.9053 18.0689 27.3372 17.6614 27.9443C17.254 28.5514 17.0894 29.2893 17.2002 30.012L17.2222 30.78ZM18.2122 35.534C18.4081 35.541 18.6033 35.5086 18.7864 35.4388C18.9695 35.369 19.1368 35.2632 19.2783 35.1276C19.4198 34.9921 19.5327 34.8295 19.6104 34.6496C19.688 34.4696 19.7287 34.276 19.7302 34.08C19.7285 33.8842 19.6875 33.6908 19.6098 33.5111C19.532 33.3313 19.4191 33.169 19.2776 33.0337C19.1361 32.8984 18.9689 32.7927 18.786 32.723C18.603 32.6534 18.4079 32.6211 18.2122 32.628C18.0165 32.6211 17.8215 32.6534 17.6385 32.723C17.4555 32.7927 17.2883 32.8984 17.1468 33.0337C17.0053 33.169 16.8924 33.3313 16.8147 33.5111C16.7369 33.6908 16.696 33.8842 16.6942 34.08C16.6957 34.276 16.7365 34.4696 16.8141 34.6496C16.8917 34.8295 17.0046 34.9921 17.1461 35.1276C17.2876 35.2632 17.4549 35.369 17.638 35.4388C17.8211 35.5086 18.0164 35.541 18.2122 35.534Z"
        fill="white"
      />
      <g filter="url(#filter1_d_1615_5072)">
        <path
          d="M50 53H185C186.326 53 187.598 53.5268 188.536 54.4645C189.473 55.4021 190 56.6739 190 58V83C190 84.3261 189.473 85.5979 188.536 86.5355C187.598 87.4732 186.326 88 185 88H50C48.6739 88 47.4021 87.4732 46.4645 86.5355C45.5268 85.5979 45 84.3261 45 83V58C45 56.6739 45.5268 55.4021 46.4645 54.4645C47.4021 53.5268 48.6739 53 50 53V53Z"
          fill="white"
        />
      </g>
      <path
        d="M114 61H88C86.3431 61 85 62.3431 85 64C85 65.6569 86.3431 67 88 67H114C115.657 67 117 65.6569 117 64C117 62.3431 115.657 61 114 61Z"
        fill="#D0D5DD"
      />
      <path
        d="M132 74H88C86.3431 74 85 75.3431 85 77C85 78.6569 86.3431 80 88 80H132C133.657 80 135 78.6569 135 77C135 75.3431 133.657 74 132 74Z"
        fill="#EAECF0"
      />
      <path
        d="M72 56H53C50.2386 56 48 58.2386 48 61V80C48 82.7614 50.2386 85 53 85H72C74.7614 85 77 82.7614 77 80V61C77 58.2386 74.7614 56 72 56Z"
        fill="#98A2B3"
      />
      <path
        d="M60.2222 73.78H62.2222L62.2442 73.01C62.2442 71.998 62.8822 71.734 63.7842 71.448C64.689 71.1906 65.4854 70.6456 66.053 69.8955C66.6206 69.1453 66.9285 68.2307 66.9302 67.29C66.95 66.7156 66.8504 66.1434 66.6376 65.6096C66.4248 65.0757 66.1036 64.5919 65.6941 64.1886C65.2846 63.7854 64.7959 63.4715 64.2589 63.2669C63.7219 63.0623 63.1482 62.9714 62.5742 63C61.9676 62.9868 61.3647 63.0969 60.8019 63.3237C60.2392 63.5505 59.7284 63.8892 59.3005 64.3194C58.8726 64.7495 58.5365 65.2621 58.3126 65.826C58.0888 66.39 57.9818 66.9935 57.9982 67.6H60.0662C60.0584 67.2651 60.1174 66.932 60.24 66.6203C60.3625 66.3085 60.546 66.0244 60.7798 65.7844C61.0136 65.5445 61.2929 65.3537 61.6013 65.2231C61.9098 65.0925 62.2412 65.0248 62.5762 65.024C62.8783 65.0065 63.1806 65.0542 63.4626 65.1639C63.7446 65.2736 63.9997 65.4428 64.2105 65.6599C64.4213 65.8769 64.5829 66.1368 64.6843 66.4219C64.7857 66.707 64.8246 67.0106 64.7982 67.312C64.7849 67.7999 64.6152 68.2706 64.3142 68.6548C64.0132 69.039 63.5968 69.3163 63.1262 69.446L62.4002 69.734C61.6894 69.9053 61.0689 70.3372 60.6614 70.9443C60.254 71.5514 60.0894 72.2893 60.2002 73.012L60.2222 73.78ZM61.2122 78.534C61.4081 78.541 61.6033 78.5086 61.7864 78.4388C61.9695 78.369 62.1368 78.2632 62.2783 78.1276C62.4198 77.9921 62.5327 77.8295 62.6104 77.6496C62.688 77.4696 62.7287 77.276 62.7302 77.08C62.7285 76.8842 62.6875 76.6907 62.6098 76.511C62.532 76.3313 62.4191 76.169 62.2776 76.0337C62.1361 75.8983 61.9689 75.7927 61.786 75.723C61.603 75.6534 61.4079 75.621 61.2122 75.628C61.0165 75.621 60.8215 75.6534 60.6385 75.723C60.4555 75.7927 60.2883 75.8983 60.1468 76.0337C60.0053 76.169 59.8924 76.3313 59.8147 76.511C59.7369 76.6907 59.696 76.8842 59.6942 77.08C59.6957 77.276 59.7365 77.4696 59.8141 77.6496C59.8917 77.8295 60.0046 77.9921 60.1461 78.1276C60.2876 78.2632 60.4549 78.369 60.638 78.4388C60.8211 78.5086 61.0164 78.541 61.2122 78.534Z"
        fill="white"
      />
      <g filter="url(#filter2_d_1615_5072)">
        <path
          d="M7 96H142C143.326 96 144.598 96.5268 145.536 97.4645C146.473 98.4021 147 99.6739 147 101V126C147 127.326 146.473 128.598 145.536 129.536C144.598 130.473 143.326 131 142 131H7C5.67392 131 4.40215 130.473 3.46447 129.536C2.52678 128.598 2 127.326 2 126V101C2 99.6739 2.52678 98.4021 3.46447 97.4645C4.40215 96.5268 5.67392 96 7 96V96Z"
          fill="white"
        />
      </g>
      <path
        d="M71 104H45C43.3431 104 42 105.343 42 107C42 108.657 43.3431 110 45 110H71C72.6569 110 74 108.657 74 107C74 105.343 72.6569 104 71 104Z"
        fill="#D0D5DD"
      />
      <path
        d="M89 117H45C43.3431 117 42 118.343 42 120C42 121.657 43.3431 123 45 123H89C90.6569 123 92 121.657 92 120C92 118.343 90.6569 117 89 117Z"
        fill="#EAECF0"
      />
      <path
        d="M29 99H10C7.23858 99 5 101.239 5 104V123C5 125.761 7.23858 128 10 128H29C31.7614 128 34 125.761 34 123V104C34 101.239 31.7614 99 29 99Z"
        fill="#98A2B3"
      />
      <path
        d="M17.2222 116.78H19.2222L19.2442 116.01C19.2442 114.998 19.8822 114.734 20.7842 114.448C21.689 114.191 22.4854 113.646 23.053 112.895C23.6206 112.145 23.9285 111.231 23.9302 110.29C23.95 109.716 23.8504 109.143 23.6376 108.61C23.4248 108.076 23.1036 107.592 22.6941 107.189C22.2846 106.785 21.7959 106.472 21.2589 106.267C20.7219 106.062 20.1482 105.971 19.5742 106C18.9676 105.987 18.3647 106.097 17.8019 106.324C17.2392 106.55 16.7284 106.889 16.3005 107.319C15.8726 107.75 15.5365 108.262 15.3126 108.826C15.0888 109.39 14.9818 109.993 14.9982 110.6H17.0662C17.0584 110.265 17.1174 109.932 17.24 109.62C17.3625 109.309 17.546 109.024 17.7798 108.784C18.0136 108.545 18.2929 108.354 18.6013 108.223C18.9098 108.093 19.2412 108.025 19.5762 108.024C19.8783 108.006 20.1806 108.054 20.4626 108.164C20.7446 108.274 20.9997 108.443 21.2105 108.66C21.4213 108.877 21.5829 109.137 21.6843 109.422C21.7857 109.707 21.8246 110.011 21.7982 110.312C21.7849 110.8 21.6152 111.271 21.3142 111.655C21.0132 112.039 20.5968 112.316 20.1262 112.446L19.4002 112.734C18.6894 112.905 18.0689 113.337 17.6614 113.944C17.254 114.551 17.0894 115.289 17.2002 116.012L17.2222 116.78ZM18.2122 121.534C18.4081 121.541 18.6033 121.509 18.7864 121.439C18.9695 121.369 19.1368 121.263 19.2783 121.128C19.4198 120.992 19.5327 120.83 19.6104 120.65C19.688 120.47 19.7287 120.276 19.7302 120.08C19.7285 119.884 19.6875 119.691 19.6098 119.511C19.532 119.331 19.4191 119.169 19.2776 119.034C19.1361 118.898 18.9689 118.793 18.786 118.723C18.603 118.653 18.4079 118.621 18.2122 118.628C18.0165 118.621 17.8215 118.653 17.6385 118.723C17.4555 118.793 17.2883 118.898 17.1468 119.034C17.0053 119.169 16.8924 119.331 16.8147 119.511C16.7369 119.691 16.696 119.884 16.6942 120.08C16.6957 120.276 16.7365 120.47 16.8141 120.65C16.8917 120.83 17.0046 120.992 17.1461 121.128C17.2876 121.263 17.4549 121.369 17.638 121.439C17.8211 121.509 18.0164 121.541 18.2122 121.534Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d_1615_5072"
          x="0"
          y="8"
          width="155"
          height="45"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="3" dy="3" />
          <feGaussianBlur stdDeviation="2.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.230539 0 0 0 0 0.370572 0 0 0 0 0.482993 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1615_5072"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1615_5072"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_1615_5072"
          x="43"
          y="51"
          width="155"
          height="45"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="3" dy="3" />
          <feGaussianBlur stdDeviation="2.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.230539 0 0 0 0 0.370572 0 0 0 0 0.482993 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1615_5072"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1615_5072"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_1615_5072"
          x="0"
          y="94"
          width="155"
          height="45"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="3" dy="3" />
          <feGaussianBlur stdDeviation="2.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.230539 0 0 0 0 0.370572 0 0 0 0 0.482993 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1615_5072"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1615_5072"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  </div>
</template>

<script>
export default {
  name: "No-Clients-Icon",
};
</script>