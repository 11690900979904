<template>
  <div
    v-if="getAssets?.displayInterestRates && getAssets?.rateHistoryData?.length"
    class="chart-info-custom-container"
  >
    <div class="container">
      <div class="chart-info">
        <ul>
          <li>
            <div class="name">30-YR. CONFORMING</div>
            <div class="price-growth">
              <strong>{{ interestRates.C30 }}%</strong>
              <span
                :class="interestRates.C30Change > 0 ? 'negative' : 'positive'"
                >{{ interestRates.C30Change | nonNegative }}</span
              >
            </div>
          </li>
          <li>
            <div class="name">30-YR. JUMBO</div>
            <div class="price-growth">
              <strong>{{ interestRates.Jumbo30 }}%</strong>
              <span
                :class="
                  interestRates.JumboChange30 > 0 ? 'negative' : 'positive'
                "
                >{{ interestRates.JumboChange30 | nonNegative }}</span
              >
            </div>
          </li>
          <li>
            <div class="name">30-YR. FHA</div>
            <div class="price-growth">
              <strong>{{ interestRates.FHA30 }}%</strong>
              <span
                :class="interestRates.FHAChange30 > 0 ? 'negative' : 'positive'"
                >{{ interestRates.FHAChange30 | nonNegative }}</span
              >
            </div>
          </li>
          <li>
            <div class="name">30-YR. VA</div>
            <div class="price-growth">
              <strong>{{ interestRates.VA30 }}%</strong>
              <span
                :class="interestRates.VAChange30 > 0 ? 'negative' : 'positive'"
                >{{ interestRates.VAChange30 | nonNegative }}</span
              >
            </div>
          </li>
          <li>
            <div class="name">30-YR. USDA</div>
            <div class="price-growth">
              <strong>{{ interestRates.USDA30 }}%</strong>
              <span
                :class="
                  interestRates.USDAChange30 > 0 ? 'negative' : 'positive'
                "
                >{{ interestRates.USDAChange30 | nonNegative }}</span
              >
            </div>
          </li>
          <li>
            <div class="name">15-YR. CONFORMING</div>
            <div class="price-growth">
              <strong>{{ interestRates.C15 }}%</strong>
              <span
                :class="interestRates.C15Change > 0 ? 'negative' : 'positive'"
                >{{ interestRates.C15Change | nonNegative }}</span
              >
            </div>
          </li>
        </ul>
        <button
          type="button"
          class="show-chart"
          data-bs-toggle="modal"
          data-bs-target="#condos_chart"
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12 2C11.4477 2 11 2.44772 11 3V14H15V3C15 2.44772 14.5523 2 14 2H12ZM6 5C6 4.44772 6.44772 4 7 4H9C9.55229 4 10 4.44772 10 5V14H6V5ZM1 9C1 8.44772 1.44772 8 2 8H4C4.55228 8 5 8.44772 5 9V14H1V9Z"
              fill="#707070"
            />
          </svg>
          Show chart
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Interest-Rate-Tool-Bar",
  props: {
    interestRates: {
      type: Object,
      required: true,
    },
  },
  filters: {
    nonNegative(value) {
      if (value < 0) {
        return Math.abs(value);
      }

      return value;
    },
  },
  computed: {
    ...mapGetters(["getAssets"]),
  },
};
</script>