<template>
  <div class="wrapper">
    <img
      v-if="teamLogo"
      :src="teamLogo"
      class="text-center mb-4 team-logo"
      alt="Team-Logo"
    />
    <div class="logout sign-in-up">
      <div class="overlay"></div>
      <div class="form pt-0 mt-4">
        <div class="form__content">
          <div class="lock-icon mb-3">
            <span>
              <logout-icon />
            </span>
          </div>
          <h2>You are logged out</h2>

          <p>Need to log in to continue</p>

          <div class="button">
            <router-link :to="logoutUrl" class="btn w-100 btn-red login">
              <span>Log In</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Toaster from "./../mixins/toasters";
import { mapActions, mapGetters } from "vuex";
import LogoutIcon from "./LogoutIcon.vue";

export default {
  name: "Logout",
  components: { LogoutIcon },
  mixins: [Toaster],
  data() {
    return {
      teamLogo: "",
      portalBg: "",
    };
  },
  computed: {
    ...mapGetters(["getProspectId", "getOrgId", "getAssets", "getIsCoPilot"]),

    logoutUrl() {
      return this.getIsCoPilot
        ? "/" +
            this.getProspectId +
            "&" +
            this.getOrgId +
            "?platform=salesforce"
        : "/" + this.getProspectId + "&" + this.getOrgId;
    },
  },
  beforeMount() {
    this.fetchUser({});
    localStorage.removeItem("authorized");
    localStorage.removeItem("default-tabs");
  },
  mounted() {
    this.teamLogo = this.getAssets.horizontalTeamLogo;
    this.portalBg = this.getAssets.agentPortalBackground;
  },
  watch: {
    portalBg(newVal) {
      if (newVal) {
        document.body.style.backgroundImage = `url(${newVal})`;
        document.body.style.backgroundPosition = "top-center";
        document.body.style.backgroundSize = "cover";
      }
    },
  },
  methods: {
    ...mapActions(["fetchUser"]),
  },
};
</script>

<style scoped>
@import "./../assets/css/logout-page.css";
</style>