<template>
  <!-- Send Mortgage Application Modal start here-->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    id="send-mortgage-app-modal"
    tabindex="-1"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title custom-send-app-modal-heading">
            {{ modalHeaderText }}
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            ref="closeSendMortageAppModal"
          >
            <img src="../assets/images/cross-icon.svg" alt="cross icon" />
          </button>
        </div>
        <div class="modal-body">
          <form class="form__wrapper-content border-0">
            <div class="form-group">
              <div class="row">
                <div class="form-input col-md-6">
                  <label class="asterisk">First Name</label>
                  <div class="input-field">
                    <i class="fa fa-user"></i>
                    <input
                      type="text"
                      v-model.trim="$v.firstName.$model"
                      placeholder="First Name"
                      :class="$v.firstName.$error ? 'is-invalid' : ''"
                    />
                  </div>
                  <span
                    v-if="$v.firstName.$error && !$v.firstName.required"
                    class="pl-10 pr-10 invalid-feedback"
                  >
                    First Name field is required
                  </span>
                </div>
                <div class="form-input col-md-6">
                  <label class="asterisk">Last Name</label>
                  <div class="input-field">
                    <i class="fa fa-user"></i>
                    <input
                      type="text"
                      v-model.trim="$v.lastName.$model"
                      placeholder="Last Name"
                      :class="$v.lastName.$error ? 'is-invalid' : ''"
                    />
                  </div>
                  <span
                    v-if="$v.lastName.$error && !$v.lastName.required"
                    class="pl-10 pr-10 invalid-feedback"
                  >
                    Last Name field is required
                  </span>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="form-input col-md-6">
                  <label class="asterisk">Email</label>
                  <div class="input-field">
                    <i class="fa fa-envelope"></i>
                    <input
                      type="email"
                      v-model.trim="$v.email.$model"
                      placeholder="Email"
                      :class="$v.email.$error ? 'is-invalid' : ''"
                    />
                  </div>
                  <div v-if="$v.email.$error">
                    <span
                      v-if="!$v.email.required"
                      class="pl-10 pr-10 invalid-feedback"
                    >
                      Email field is required
                    </span>
                    <span
                      v-else-if="!$v.email.email"
                      class="pl-10 pr-10 invalid-feedback"
                    >
                      Please enter a valid email address
                    </span>
                  </div>
                </div>
                <div class="form-input col-md-6">
                  <label class="asterisk">Phone</label>
                  <div class="input-field">
                    <i class="fa fa-phone-alt"></i>
                    <input
                      type="text"
                      v-model.trim="$v.phone.$model"
                      inputmode="tel"
                      :class="$v.phone.$error ? 'is-invalid' : ''"
                      placeholder="Phone"
                      @input="numberWithDash($event, phone, false)"
                    />
                  </div>
                  <div v-if="$v.phone.$error">
                    <span
                      v-if="!$v.phone.required"
                      class="pl-10 pr-10 invalid-feedback"
                    >
                      Phone field is required
                    </span>
                    <span
                      v-else-if="!$v.phone.minLength"
                      class="pl-10 pr-10 invalid-feedback"
                    >
                      Phone field must have a valid 10 digit number (without
                      Country code)
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="form-input col-12">
                  <label>Notes</label>
                  <div class="input-field">
                    <i class="fa fa-pencil-alt"></i>
                    <input
                      type="text"
                      v-model.trim="notes"
                      placeholder="Notes"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="divider mt-3" v-if="additionalClient"></div>
            <div class="form-group" v-if="!additionalClient">
              <button
                @click="additionalClient = true"
                type="button"
                class="add-btn ml-5"
                id="additional-client"
              >
                <i class="fa fa-plus"></i>
                Add additional client
              </button>
            </div>
            <div v-if="additionalClient" class="make-copy">
              <div class="form-group">
                <div class="row">
                  <div class="form-input col-md-6">
                    <label class="asterisk">First Name</label>
                    <div class="input-field">
                      <i class="fa fa-user"></i>
                      <input
                        type="text"
                        v-model.trim="$v.addon.firstName.$model"
                        placeholder="First Name"
                        :class="$v.addon.firstName.$error ? 'is-invalid' : ''"
                      />
                    </div>
                    <span
                      v-if="
                        $v.addon.firstName.$error &&
                        !$v.addon.firstName.required
                      "
                      class="pr-10 pl-10 invalid-feedback"
                    >
                      First Name field is required
                    </span>
                  </div>
                  <div class="form-input col-md-6">
                    <label class="asterisk">Last Name</label>
                    <div class="input-field">
                      <i class="fa fa-user"></i>
                      <input
                        type="text"
                        v-model.trim="$v.addon.lastName.$model"
                        placeholder="Last Name"
                        :class="$v.addon.lastName.$error ? 'is-invalid' : ''"
                      />
                    </div>
                    <span
                      v-if="
                        $v.addon.lastName.$error && !$v.addon.lastName.required
                      "
                      class="pr-10 pl-10 invalid-feedback"
                    >
                      Last Name field is required
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="form-input col-md-6">
                    <label>Email</label>
                    <div class="input-field">
                      <i class="fa fa-envelope"></i>
                      <input
                        type="text"
                        v-model.trim="$v.addon.email.$model"
                        placeholder="Email"
                        :class="$v.addon.email.$error ? 'is-invalid' : ''"
                      />
                    </div>
                    <span
                      v-if="$v.addon.email.$error && !$v.addon.email.email"
                      class="pr-10 pl-10 invalid-feedback"
                    >
                      Please enter a valid email address
                    </span>
                  </div>
                  <div class="form-input col-md-6 mb-0">
                    <label>Phone</label>
                    <div class="input-field">
                      <i class="fa fa-phone-alt"></i>
                      <input
                        type="text"
                        v-model.trim="$v.addon.phone.$model"
                        placeholder="Phone"
                        :class="$v.addon.phone.$error ? 'is-invalid' : ''"
                        @input="numberWithDash($event, addon.phone, true)"
                      />
                    </div>
                    <span
                      v-if="$v.addon.phone.$error && !$v.addon.phone.minLength"
                      class="pr-10 pl-10 invalid-feedback"
                    >
                      Phone field must have a valid 10 digit number (without
                      Country code)
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="form-input col-12">
                    <label>Notes</label>
                    <div class="input-field">
                      <i class="fa fa-pencil-alt"></i>
                      <input
                        type="text"
                        v-model.trim="addon.notes"
                        placeholder="Notes"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <a
                @click="additionalClient = false"
                class="text-danger ml-5"
                role="button"
                ><i class="fa fa-minus-circle" aria-hidden="true"></i> Remove</a
              >
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary rounded"
            @click="sendMortgageApplication"
            :disabled="$v.$invalid"
          >
            {{ modalHeaderText === "Add Client" ? "Add" : "Send" }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Send Mortgage Application Modal end here-->
</template>

<script>
import { mapGetters } from "vuex";
import { eventBus } from "../event-bus";
import Toasters from "../mixins/toasters";
import {
  required,
  requiredIf,
  email,
  minLength,
} from "vuelidate/lib/validators";

export default {
  name: "Send-Mortgage-App-Modal",
  mixins: [Toasters],
  data() {
    return {
      modalHeaderText: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      notes: "",
      additionalClient: false,
      addon: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        notes: "",
      },
    };
  },
  validations: {
    firstName: {
      required,
    },
    lastName: {
      required,
    },
    email: {
      required,
      email,
    },
    phone: {
      required,
      minLength: minLength(12),
    },
    addon: {
      firstName: {
        required: requiredIf(function () {
          return this.additionalClient;
        }),
      },
      lastName: {
        required: requiredIf(function () {
          return this.additionalClient;
        }),
      },
      email: {
        email,
      },
      phone: {
        minLength: minLength(12),
      },
    },
  },
  watch: {
    additionalClient(newVal) {
      if (!newVal) {
        this.addon.firstName = "";
        this.addon.lastName = "";
        this.addon.email = "";
        this.addon.phone = "";
        this.addon.notes = "";
        this.$v.addon.$reset();
      }
    },
  },
  computed: {
    ...mapGetters(["getOrgId", "getProspectId"]),
  },
  created() {
    eventBus.$on("modal-text", (data) => {
      this.modalHeaderText = data;
    });
  },
  methods: {
    async sendMortgageApplication() {
      eventBus.$emit("loader-status", true);

      const payLoad = {
        accountId: this.getProspectId,
        orgId: this.getOrgId,
        email: this.email,
        firstName: this.firstName,
        lastName: this.lastName,
        phone: this.phone,
        notes: this.notes ? this.notes : null,
        additionalClient: this.additionalClient,
        coEmail: this.additionalClient ? this.addon.email : null,
        coFirstName: this.additionalClient ? this.addon.firstName : null,
        coLastName: this.additionalClient ? this.addon.lastName : null,
        coPhone: this.additionalClient ? this.addon.phone : null,
        coNotes: this.additionalClient ? this.addon.notes : null,
      };

      await this.$http
        .post("/referral/send", payLoad)
        .then((response) => {
          let resp = response.data;
          console.log(resp);
          if (resp.status === 511) this.$router.push("/");

          if (resp.status === 200) {
            this.$refs.closeSendMortageAppModal.click();

            if (this.modalHeaderText === "Add Client") {
              this.$confetti.start();
              this.success(
                "The new client successfully added to the pipeline."
              );

              setTimeout(() => {
                this.$confetti.stop();
              }, 7000);
            }

            this.firstName = "";
            this.lastName = "";
            this.email = "";
            this.phone = "";
            this.notes = "";
            this.additionalClient = false;
            this.$v.$reset();

            if (this.modalHeaderText === "Add Client") {
              eventBus.$emit("triggerPipelineAPI", true);
            }
          }

          if (resp.status !== 511 && resp.status !== 200) {
            if (resp?.message && resp.message !== "") {
              this.error(resp.message);
            } else {
              this.error("Referral not submitted, lead info already received.");
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });

      eventBus.$emit("loader-status", false);
    },

    numberWithDash(event, number, addon) {
      if (["Arrow", "Backspace", "Shift"].includes(event.key)) {
        this.preventNextIteration = true;
        return;
      }

      if (this.preventNextIteration) {
        this.preventNextIteration = false;
        return;
      }

      if (number) {
        const x = number
          .replace(/\D/g, "")
          .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

        if (!addon)
          this.phone =
            x[1] + (x[2] ? "-" + x[2] : "") + (x[3] ? "-" + x[3] : "");
        else
          this.addon.phone =
            x[1] + (x[2] ? "-" + x[2] : "") + (x[3] ? "-" + x[3] : "");
      }
    },
  },
};
</script>

<style scoped>
.custom-send-app-modal-heading {
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
  color: #262a31;
}
</style>