<template>
  <div class="no-freeze-spinner">
    <div id="no-freeze-spinner">
      <div>
        <i class="fa fa-envelope"></i>
        <i class="fa fa-list"></i>
        <i class="fas fa-building"></i>
        <i class="fa fa-user-tie"></i>
        <i class="fa fa-calculator"></i>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loader",
};
</script>

<style>
@import "./../assets/css/loader.css";
</style>