<template>
  <div>
    <loader v-if="loading" />
    <div class="wrapper">
      <sidebar></sidebar>
      <router-view></router-view>
      <SendMortgageAppModal />
    </div>
  </div>
</template>

<script>
import { eventBus } from "./../event-bus";
import Sidebar from "./Sidebar";
import Loader from "./Loader";
import { mapActions, mapGetters } from "vuex";
import Shades from "./../mixins/shades";
import SendMortgageAppModal from "./SendMortgageAppModal.vue";

export default {
  name: "Layout",
  components: {
    Sidebar,
    Loader,
    SendMortgageAppModal,
  },
  mixins: [Shades],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["getProspectId", "getOrgId", "getAssets"]),
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler() {
        this.getPortalAssets();
      },
    },
  },
  created() {
    eventBus.$on("loader-status", (data) => {
      this.loading = data;
    });
  },
  beforeMount() {
    this.generateShades();
  },
  methods: {
    ...mapActions(["fetchAssets"]),

    getPortalAssets() {
      this.$http
        .get(
          "/accountAuth/GetAsset/" + this.getProspectId + "/" + this.getOrgId
        )
        .then((response) => {
          let resp = response.data;

          if (resp.status === 511) this.$router.push("/");

          if (resp.status !== 511) {
            this.fetchAssets(resp.assetData);
            this.generateShades();

            const favicon = document.getElementById("favicon");
            favicon.setAttribute("href", resp.assetData?.roundColorTeamLogo);

            this.$localStore.set(
              "primary-color",
              resp.assetData.primaryBrandColor || "#A6383C"
            );

            this.$localStore.set("default-tabs", resp.assetData.defaultTabs);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    generateShades() {
      let primaryColor = this.getAssets.primaryBrandColor || "#A6383C";

      document.body.style.setProperty(
        "--primary-color",
        primaryColor || "#A6383C",
        "important"
      );

      document.body.style.setProperty(
        "--shade-one",
        this.getShadesOfColor(primaryColor, -0.1),
        "important"
      );

      document.body.style.setProperty(
        "--shade-two",
        this.getShadesOfColor(primaryColor, -0.3),
        "important"
      );

      document.body.style.setProperty(
        "--shade-three",
        this.getShadesOfColor(primaryColor, 0),
        "important"
      );

      document.body.style.setProperty(
        "--shade-four",
        this.getShadesOfColor(primaryColor, 0.2),
        "important"
      );
    },
  },
};
</script>

<style>
@import "./../assets/css/app.css";
@import "./../assets/css/custom.css";
@import "./../assets/css/condos.css";
</style>
