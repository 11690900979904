<template>
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1H11M11 1V11M11 1L1 11"
      :stroke="
        getAssets.primaryBrandColor ? getAssets.primaryBrandColor : '#c34132'
      "
      stroke-width="1.5"
    />
  </svg>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Read-Reviews-Icon",
  computed: {
    ...mapGetters(["getAssets"]),
  },
};
</script>