export default {
    methods: {
        roundTheNumber(number, decimalPlaces) {
            return Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
        },

        maskTheNumber(price) {
            let reversed_number = price.toString()
                .replace(/,/gi, "")
                .split("")
                .reverse()
                .join("");

            let add_comma = reversed_number
                .replace(/(.{3})/g, "$1,")
                .split("")
                .reverse()
                .join("");

            if (add_comma.substring(0, 1) === ",")
                return add_comma.substring(1, add_comma.length);
            else return add_comma;
        },
    },
}