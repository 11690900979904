import Layout from "./components/Layout";
import TwoFactorAuth from "./components/Two-Factor-Auth";
import ApprovalLetters from "./components/Approval-Letter";
import Pipeline from "./components/Pipeline";
import Condos from "./components/Condos";
import ReferClient from "./components/Refer-a-Client";
import MortgageCalc from "./components/Mortgage-Calc";
import Logout from "./components/Logout";
import UnderConstruction from './components/Under-Construction'

const router = [
    {
        path: "/:unique_key",
        component: TwoFactorAuth,
        name: "Two-Factor-Auth",
        default: true
    },
    {
        path: '/',
        component: UnderConstruction,
        name: 'Under-Construction',
        default: true
    },
    {
        path: "/portal/logout",
        component: Logout,
        name: "Logout",
        default: true
    },
    {
        path: "/admin",
        component: Layout,
        meta: {
            requiresAuth: true,
        },
        children: [{
            path: "approval-letters",
            name: "Approval-Letters",
            component: ApprovalLetters,
            default: true,
        }, {
            path: "pipeline",
            name: "Pipeline",
            component: Pipeline,
            default: true
        }, {
            path: "condos",
            name: "Condos",
            component: Condos,
            default: true
        }, {
            path: "refer-a-client",
            name: "Refer-a-Client",
            component: ReferClient,
            default: true
        }, {
            path: "mortgage-calculator",
            name: "Mortgage-Calculator",
            component: MortgageCalc,
            default: true
        }],
    }
];

export default router;
