<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9 16C12.866 16 16 12.866 16 9C16 5.13401 12.866 2 9 2C5.13401 2 2 5.13401 2 9C2 12.866 5.13401 16 9 16ZM9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z"
      :fill="getAssets.primaryBrandColor || '#A6383C'"
    />
    <path
      d="M8.99999 11.2202C8.89246 11.2202 8.78494 11.1792 8.70296 11.0972L6.12309 8.51733C5.95897 8.35321 5.95897 8.08713 6.12309 7.92308C6.28713 7.75904 6.55316 7.75904 6.71729 7.92308L8.99999 10.2059L11.2827 7.92316C11.4468 7.75912 11.7128 7.75912 11.8769 7.92316C12.041 8.08721 12.041 8.35329 11.8769 8.51741L9.29702 11.0973C9.215 11.1793 9.10748 11.2202 8.99999 11.2202Z"
      :fill="getAssets.primaryBrandColor || '#A6383C'"
      :stroke="getAssets.primaryBrandColor || '#A6383C'"
    />
  </svg>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "BaseDownArrow",
  computed: {
    ...mapGetters(["getAssets"]),
  },
};
</script>