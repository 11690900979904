<template>
  <Doughnut :chart-options="options" :chart-data="chartData" />
</template>

<script>
import { Doughnut } from "vue-chartjs/legacy";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

export default {
  name: 'DoughnutChart',
  components: {
    Doughnut
  },
  props: {
    chartData: {
      required: true,
      type: Object,
    },
    options: {
      default: {},
    },
  },
};
</script>
