export default {
    data() {
        return {
            ascendName: false,
            descendName: false,
            ascendStatus: false,
            descendStatus: false,
            ascendArea: false,
            descendArea: false,
            ascendPDP: false,
            descendPDP: false,
            ascendSDP: false,
            descendSDP: false,
            ascendIDP: false,
            descendIDP: false,
        }
    },
    methods: {
        sortArea(resp) {
            this.areaFilter = resp.area.sort((a, b) => {
                if (a < b) return -1;
                return 0;
            });
        },

        sortDownPayment(resp) {
            this.dpFilter = resp.downPayment.sort((a, b) => {
                if (a < b) return -1;
                return 0;
            });
        },

        sortByName() {
            let theResult;

            if (!this.ascendName && !this.descendName) {
                this.ascendName = true;
                this.descendName = false;
            } else {
                this.ascendName = !this.ascendName;
                this.descendName = !this.descendName;
            }

            if (this.ascendName)
                theResult = this.condosList.sort((a, b) => {
                    if (a.name < b.name) return -1;
                    return 0;
                });

            if (this.descendName)
                theResult = this.condosList.sort((a, b) => {
                    if (b.name < a.name) return -1;
                    return 0;
                });

            this.condosList = theResult;

            this.setPages(this.condosList);
            this.paginate(this.condosList);
            this.page = 1;
        },

        sortByStatus() {
            let theResult;

            if (!this.ascendStatus && !this.descendStatus) {
                this.ascendStatus = true;
                this.descendStatus = false;
            } else {
                this.ascendStatus = !this.ascendStatus;
                this.descendStatus = !this.descendStatus;
            }

            if (this.ascendStatus)
                theResult = this.condosList.sort((a, b) => {
                    if (a.approvalStatus < b.approvalStatus) return -1;
                    return 0;
                });

            if (this.descendStatus)
                theResult = this.condosList.sort((a, b) => {
                    if (b.approvalStatus < a.approvalStatus) return -1;
                    return 0;
                });

            this.condosList = theResult;

            this.setPages(this.condosList);
            this.paginate(this.condosList);
            this.page = 1;
        },

        sortByArea() {
            let theResult;

            if (!this.ascendArea && !this.descendArea) {
                this.ascendArea = true;
                this.descendArea = false;
            } else {
                this.ascendArea = !this.ascendArea;
                this.descendArea = !this.descendArea;
            }

            if (this.ascendArea)
                theResult = this.condosList.sort((a, b) => {
                    if (a.area < b.area) return -1;
                    return 0;
                });

            if (this.descendArea)
                theResult = this.condosList.sort((a, b) => {
                    if (b.area < a.area) return -1;
                    return 0;
                });

            this.condosList = theResult;

            this.setPages(this.condosList);
            this.paginate(this.condosList);
            this.page = 1;
        },

        sortByPDP() {
            let theResult;

            if (!this.ascendPDP && !this.descendPDP) {
                this.ascendPDP = true;
                this.descendPDP = false;
            } else {
                this.ascendPDP = !this.ascendPDP;
                this.descendPDP = !this.descendPDP;
            }

            if (this.ascendPDP)
                theResult = this.condosList.sort((a, b) => {
                    if (a.primaryDP < b.primaryDP) return -1;
                    return 0;
                });

            if (this.descendPDP)
                theResult = this.condosList.sort((a, b) => {
                    if (b.primaryDP < a.primaryDP) return -1;
                    return 0;
                });

            this.condosList = theResult;

            this.setPages(this.condosList);
            this.paginate(this.condosList);
            this.page = 1;
        },

        sortBySDP() {
            let theResult;

            if (!this.ascendSDP && !this.descendSDP) {
                this.ascendSDP = true;
                this.descendSDP = false;
            } else {
                this.ascendSDP = !this.ascendSDP;
                this.descendSDP = !this.descendSDP;
            }

            if (this.ascendSDP)
                theResult = this.condosList.sort((a, b) => {
                    if (a.secondDp < b.secondDp) return -1;
                    return 0;
                });

            if (this.descendSDP)
                theResult = this.condosList.sort((a, b) => {
                    if (b.secondDp < a.secondDp) return -1;
                    return 0;
                });

            this.condosList = theResult;

            this.setPages(this.condosList);
            this.paginate(this.condosList);
            this.page = 1;
        },

        sortByIDP() {
            let theResult;

            if (!this.ascendIDP && !this.descendIDP) {
                this.ascendIDP = true;
                this.descendIDP = false;
            } else {
                this.ascendIDP = !this.ascendIDP;
                this.descendIDP = !this.descendIDP;
            }

            if (this.ascendIDP)
                theResult = this.condosList.sort((a, b) => {
                    if (a.invDP < b.invDP) return -1;
                    return 0;
                });

            if (this.descendIDP)
                theResult = this.condosList.sort((a, b) => {
                    if (b.invDP < a.invDP) return -1;
                    return 0;
                });

            this.condosList = theResult;

            this.setPages(this.condosList);
            this.paginate(this.condosList);
            this.page = 1;
        },
    },
}